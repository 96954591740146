import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

const JapanPage = ({data}) => (
  <Layout>
    <SEO title="Japan" />
    <h2>Japan</h2>
    <div>

Neighborhoods/Things to Do 

1) Shibuya/Harajuku - shopping madness, Harajuku is where you'll see all the cosplay
Stores: 
Loft: this is stationary heaven
Tokyu Hands: good for souvenirs
Kiddyland: lots of typical Japanese toys/anime characters
People watching
Go to the Starbucks at Shibuya crossing (can't miss it) and look down at all the people
Restaurants
Uobei: true conveyer belt sushi experience where you order from a computer. the line can be long, but it moves quickly

2) Ebisu/Daikanyama - chic/hipster area, definitely worth checking out
Lots of boutique stores
Restaurant:  Afuri - nice ramen 
Bars: Tram
Cat Cafe: Cafe Melange

3) Roppongi - nice shopping center, interesting architecture and museums, international scene
Museums: 
Mori Art Museum
Tokyo National Art Center: really cool architecture
Restaurants: Midtown has a lot of nice, fancy restaurants with good outdoor seating
Michelin star restaurant: Probably the best restaurant we went to. It is a traditional style Japanese restaurant and is relatively inexpensive when you go for lunch. We always got the lunch set, which comes with a bunch of different small dishes. This is located more in the Minato neighborhood, right near Tokyo Tower 
Brunch: 
Mercer Cafe: more western style brunch

4) Asakusa
Temple: Sensoji, there are lots of souveneir stalls leading up to the temple that are worth checking out
Cat Cafe: Cat Cafe Asakusa (this one's better because they have rescue cats)

5) Akihabara - electronic city, very stereotypically Tokyo
not a ton to do here, but you can play at "Pachinkos," casino/gaming areas

6) Shinjuku – typical Tokyo - lights, skyscrapers, noise
"Golden Gai" - a bunch of tiny streets which can fit maybe one or two people wide, all filled with tiny bars in which at most 5 people can sit usually. It's always really personal in there, and if you go to one of the foreigner friendly bars, then you won't have to pay a table charge.

7) Sangenjaya – very close to Setagaya with lots of small alleyways and restaurants
Okonomiyaki restaurant

8) Hiking
Mt. Takao
Closest hiking trail near Tokyo
Takamizu-sanzan
Longer hike with village views and a bridge crossing
Can use the website description to guide you
This website also has some other well-explained hikes: http://aichaobang.blogspot.jp/p/blog-page.html

9) Food to try:
Okonomiyaki (Japanese pancake)
Ramen
Onigiri rice balls at the convenience store (the blue packaging is Tuna, which is the best)
Conveyer belt sushi
Yakitori (grilled meat)
Crazy vending machine drinks (we liked the milk tea)
Matcha tea
Natto (if you're feeling adventurous) 
Mochi with red bean paste
Tempanyaki (fried octopus balls)
Tonkatsu (breaded meat)

10) Random tips:
Cash is used for most things
Stay left on elevators if standing
Don't blow your nose in public 
7/11's and Family Marts are everywhere and have everything, including ATMs. Use the 7/11 ATMs to get cash.
People are very nice and will help if you look lost

11) Transportation
Google maps is your savior. 
Get a pasmo or suica card. This is like the clipper card in San Francisco, and is incredibly useful in Tokyo. You can get these cards at the machines in any train station, possibly even at the airport. You can get a card with your name on it, or an anonymous card. Just get the anonymous one. At the end of your trip you can get the remaining money on the card back (there might be a small fee, can't remember). 
The trains stop running around midnight, but there will always be taxis.
If you're coming from Narita airport, take the Narita airport express and show your passport to get a discount (at least, this was the case when I was there). Keep in mind that the trip from Narita airport to Tokyo will take roughly 1.5 hours. If you're coming from Haneda then just use Google maps to get to wherever you need to be.


12) Resources:
http://www.timeout.jp/en/tokyo
http://www.tokyoweekender.com/
http://tokyocheapo.com/



    </div>
  </Layout>
);

export default JapanPage;
